<template>
  <h2 class="content-block">{{title}}</h2>
  <DxButton class="DxTextBox" icon="add" type="success" text="Cadastrar projeto" @click="open_popup()" />
  <DxPopup v-if="showPopup" :visible="showPopup" :shadding="true" height="90%" width="50%" :resize-enabled="true">
    <DxToolbarItem widget="dxButton" toolbar="top" location="after" :options="button_cancelar" />
    <consultaPadrao :projeto="projetoSelect!={}?projetoSelect:{}" :kanban="showPopup" />
  </DxPopup>
  <div id="kanban">
    <DxScrollView class="scrollable-board" direction="horizontal" show-scrollbar="always">
      <div v-for="(list, listIndex) in lists" :key="listIndex" class="list" @dragover.prevent
        @drop="onDrop($event, status[listIndex])">
        <div class="list-title">{{ status[listIndex].nm_status_todo }}</div>
        <div class="scrollable-list" show-scrollbar="always">
          <div v-for="task in list.json" :key="task" class="card dx-card" draggable="true"
            @dragstart="startDrag($event, task)" @click="abrePopupEdicao(task)">
            <div class="card-priority" :style="{ backgroundColor: task.cor }"></div>
            <div class="card-content">
              <div class="card-subject">{{ task.titulo }}</div>
              <div class="card-description">{{ task.descricao }}</div>
            </div>
          </div>
        </div>
      </div>
    </DxScrollView>
  </div>
</template>

<script>
import api from '../api/api';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import DxButton from 'devextreme-vue/button'
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import consultaPadrao from './consulta-padrao.vue';
export default {
  data() {
    return {
      title: '',
      tela: {},
      status: [],
      lists: [],
      draggedTask: null,
      showPopup: false,
      editProjeto: false,
      projetoSelect: {},
      button_cancelar: {
        width: 15,
        icon: 'close',
        type: 'danger',
        onClick: () => {
            this.showPopup = false,
            this.carrega_dados()
        }
      },
    };
  },
  async created() {
    await this.carrega_dados()
  },
  methods: {
    async carrega_dados() {
      var var_tela = JSON.parse(localStorage.getItem("tela"))
      this.title = var_tela.text
      this.tela = await api.login('consulta_kanban_tela', [var_tela.cd_tela_empresa])
      this.status = await api.get(this.tela.nm_api_status, [])
      this.lists = await api.get(this.tela.nm_api_lista, [])
      this.lists.forEach((e, i) => {
        this.lists[i].json = JSON.parse(e.json)
      })
    },
    startDrag(evnt, task) {
      this.draggedTask = task;
      console.log('START FUNCIONA')
      evnt.dataTransfer.dropEffect = 'move'
      evnt.dataTransfer.effectAllowed = 'move'
      evnt.dataTransfer.setData('taskID', task)
    },
    async onDrop(evnt, newStatus) {
      if (this.draggedTask) {
        var json = {
          old: this.draggedTask,
          new: newStatus
        }
        await api.post(this.tela.nm_api_update, json);
        await this.carrega_dados();
        this.draggedTask = null;
      }
    },
    async cadastraProjeto() {
      this.fecharPopup();
      await this.carrega_dados();
      this.editProjeto = false
    },
    fecharPopup() {
      this.showPopup = false;
      this.editProjeto = false
    },
    abrePopupEdicao(task) {
      this.projetoSelect = { ...task } //Abre o projeto com as informações
      console.log(this.projetoSelect)
      this.showPopup = true
      this.editProjeto = true
    },
    open_popup(){
      this.showPopup = true
      this.projetoSelect = null
    },
  },
  components: {
    DxScrollView,
    DxButton,
    consultaPadrao,
    DxPopup, 
    DxToolbarItem
  }
};
</script>

<style>
#kanban {
  margin: 20px;
  white-space: nowrap;
  flex-wrap: nowrap;
}

.list {
  border-radius: 8px;
  margin: 5px;
  background-color: rgba(255, 255, 255, 0.4);
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  flex-direction: column;
}

.list-title {
  color: var(--dx-color-text);
  font-size: 16px;
  padding: 10px;
  padding-left: 30px;
  margin-bottom: -10px;
  font-weight: bold;
  cursor: pointer;
}

.scrollable-list {
  height: 400px;
  width: 260px;
}

.sortable-cards {
  min-height: 380px;
}

.card {
  color: var(--dx-color-text);
  position: relative;
  background-color: var(--dx-component-color-bg);
  box-sizing: border-box;
  width: 230px;
  padding: 15px;
  margin: 15px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.card-subject {
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 10px;
}

.card-content {
  margin-left: 20px;
  flex: 1;
}

.card-priority {
  position: absolute;
  top: 0;
  bottom: 10px;
  left: 5px;
  width: 5px;
  border-radius: 4px 0 0 4px;
}

.DxTextBox {
  margin-left: 40px;
}
</style>